
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    dictType: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    remarks: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    systemFlag: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }]
}
const infoRules = {
    dictType: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    value: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    label: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    labelList: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
export default{
    mainRules,infoRules
}