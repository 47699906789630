<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">

            <el-form-item :label="$t('adminDict.name2')" prop="dictType">
                <el-input v-model="fromValue.dictType" :placeholder="$t('inputTit.shuru')" disabled/>
            </el-form-item>

            <el-form-item :label="$t('adminDict.name7')" prop="value">
                <el-input v-model="fromValue.value" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('adminDict.name8')" prop="labelList">
                <el-input class="inputText" v-model="fromValue.labelList[index].name" :placeholder="$t('inputTit.shuru')" v-for='(item,index) in showData' :key='item.label'>
                    <template #prepend>{{item.label}}</template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('adminDict.name9')" prop="sortOrder">
                <el-input v-model="fromValue.sortOrder" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('adminDict.name3')" prop="description">
                <el-input v-model="fromValue.description" :placeholder="$t('inputTit.shuru')" type="textarea"/>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    // import { parmStore } from '@/store/parms.js'
    import { isHaveParm  } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        id: '',dictType: '',value:'',labelList:[],description: '',sortOrder:'',dictId:'',label:'',
        tenantId: ''
    })
    
    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.infoRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun','closeFun'])
    const showData = ref([])
    const bindData = ()=>{
        isHaveParm(2,(data)=>{
            let showData_ = [];let parmData_ = [];
            for(let i = 0;i < data.length;++ i){
                showData_.push({
                    value: data[i].value,
                    label: data[i].label,
                })
                parmData_.push({
                    language: data[i].value,
                    name: '',
                })
            }
            showData.value = showData_
            fromValue.labelList = parmData_
        })
    }
    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    fromValue.label = fromValue.labelList[0].name;
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.id
                    Api_.addDictItem(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateDictItem(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(e){
            if(obj.id){
                fromValue.id = obj.id;
                fromValue.value = obj.value;
                fromValue.label = obj.label;
                fromValue.description = obj.description;
                fromValue.sortOrder = obj.sortOrder;
                fromValue.tenantId = obj.tenantId;
                fromValue.dictType = obj.dictType;
                fromValue.dictId = obj.dictId;
            }
            Api_.getDictListItemDetail({
                dictItemId: obj.id
            }).then(res=>{
                if(res.data.records.length > 0){
                    for(let item of fromValue.labelList){//应该以字典查到的语种为判断唯一的依据
                        for(var i = 0 ; i < res.data.records.length ; i++){
                            if(item.language == res.data.records[i].language){
                                item.name = res.data.records[i].name
                                item.nameId = res.data.records[i].nameId
                                break;
                            }
                        }
                    }
                }
            })
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
            fromValue.dictType = obj.dictType;
            fromValue.dictId = obj.id;
            fromValue.tenantId = obj.tenantId;
            fromValue.labelList = []
        }
        bindData()
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
    .inputText{
        margin-bottom: 10px;
    }
</style>